import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./SecurityGuy.scss";
import { Content } from "antd/es/layout/layout";
import { theme, notification } from "antd";
import { UsergroupDeleteOutlined } from "@ant-design/icons";
import RolesHelper from "../../common/services/permissions/RolesHelper";
import { SecurityGuyHeader } from "./components";
import { useSearchParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  get_queued_patients,
  postPatientQueue,
  postPatient,
  deleteSelectedQueuedPatient,
} from "../../features/patient/patientActions";
import { resetPatientError } from "../../features/patient/patientSLice";
import { PatientList } from "../../components";
import { ConfirmModal, CreatePatient } from "../../modals";
import Utils from "../../utils";

export default function SecurityGuy() {
  const { useToken } = theme;
  const { token } = useToken();
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = useCallback(
    (type, title, text) => {
      api[type]({
        message: title,
        description: text,
        placement: "top",
      });
    },
    [api]
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state?.auth);
  const {
    error,
    loading,
    queued_patients: patients,
  } = useSelector((state) => state?.patient);
  const role = useMemo(() => {
    return isAuthenticated ? RolesHelper?.getRole() : {};
  }, [isAuthenticated]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const [is_patient_opened, set_is_patient_opened] = useState(false);

  useEffect(() => {
    const params = Utils?.getUrlParamsAsObject(searchParams);
    const check_identity = (params) => {
      for (let key in params) {
        if (key !== "security_page" && key !== "security_perPage") {
          return true;
        }
      }
      return false;
    };
    let is_included = check_identity(params);
    if (!is_included) {
      dispatch(get_queued_patients());
    } else {
      setSearchParams({});
    }
  }, [dispatch, isAuthenticated, searchParams, setSearchParams]);

  useEffect(() => {
    if (location?.pathname === "/" && !is_patient_opened && role?.is_security) {
      if (error && !loading) {
        for (let key in error) {
          openNotificationWithIcon("error", "Error", error[key]);
        }
        dispatch(resetPatientError());
      }
    }
  }, [
    error,
    loading,
    location,
    role?.is_security,
    openNotificationWithIcon,
    dispatch,
    is_patient_opened,
  ]);

  const addPatient = (params, callBack, is_in_queue) => {
    dispatch(postPatient(params))?.then((res) => {
      if (res?.type === "post_patient/fulfilled") {
        set_is_patient_opened(false);
        if (typeof callBack === "function") {
          callBack();
        }
        if (is_in_queue) {
          dispatch(
            postPatientQueue({
              id: res?.payload?.id,
            })
          )?.then((queue_res) => {
            if (queue_res?.type === "post_patient_queue/fulfilled") {
              dispatch(get_queued_patients());
            }
          });
        }
      }
    });
  };

  const removeFromQueue = (patient) => {
    setSelectedPatient(patient);
    setIsConfirmModalOpened(true);
  };

  return (
    <>
      {contextHolder}
      <Content
        className="securityGuy-cont"
        style={{
          backgroundColor: token?.Layout?.contentBg,
        }}>
        <SecurityGuyHeader
          token={token}
          set_is_patient_opened={set_is_patient_opened}
        />
        <PatientList
          loading={loading}
          patients={patients}
          removeFromQueue={removeFromQueue}
        />
        <ConfirmModal
          token={token}
          isOpened={isConfirmModalOpened}
          title={"Are you sure"}
          subTitle={`You want to remove ${
            selectedPatient?.first_name ? selectedPatient?.first_name : ""
          } ${selectedPatient?.last_name ? selectedPatient?.last_name : ""}
          ${
            selectedPatient?.phone_number
              ? `+1 ${Utils?.format_phone_number(
                  selectedPatient?.phone_number
                )}`
              : ""
          } from queue ?`}
          icon={<UsergroupDeleteOutlined style={{ fontSize: "24px" }} />}
          onCancel={() => {
            setIsConfirmModalOpened(false);
            setSelectedPatient(null);
          }}
          onOk={() => {
            setIsConfirmModalOpened(false);
            setSelectedPatient(null);
            dispatch(deleteSelectedQueuedPatient(selectedPatient))?.then(
              (res) => {
                if (res?.type === "delete_selected_queued_patient/fulfilled") {
                  dispatch(get_queued_patients());
                }
              }
            );
          }}
        />
        <CreatePatient
          token={token}
          title={"Create New Patient"}
          isOpened={is_patient_opened}
          onCancel={() => {
            set_is_patient_opened(false);
          }}
          onOk={(params, callBack, is_in_queue) => {
            addPatient(params, callBack, is_in_queue);
          }}
        />
      </Content>
    </>
  );
}
