import { createSlice } from "@reduxjs/toolkit";
import {
  createPatient,
  getPatientDetails,
  getPatientShippingAddresses,
  postPatientShippingAddress,
  patchPatientShippingAddress,
  get_queued_patients,
  postPatientQueue,
  deleteQueuedPatient,
  deleteSelectedQueuedPatient,
  postPatient,
  patchPatient,
} from "./patientActions";
import LocalStorageService from "../../common/services/LocalStorageService";

const initialState = {
  loading: false,
  error: null,
  success: false,
  unAuthenticatedPatientId: LocalStorageService.get("patientId") || "",
  patient: {},
  shipping_addresses: {},
  queued_patients: [],
};

const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    resetPatientError: (state, action) => {
      if (action?.payload) {
        delete state.error[action?.payload];
        if (Object.keys(state.error)?.length === 0) {
          state.error = null;
        }
      } else {
        state.error = null;
      }
      state.loading = false;
      state.success = false;
    },
    addPatient: (state, action) => {
      state.unAuthenticatedPatientId = action?.payload?.id;
      LocalStorageService.set("patientId", action?.payload?.id);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPatient.pending, (state) => {
        state.loading = true;
      })
      .addCase(createPatient.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createPatient.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getPatientDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPatientDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.patient = action.payload;
      })
      .addCase(getPatientDetails.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(postPatient.pending, (state) => {
        state.loading = true;
      })
      .addCase(postPatient.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(postPatient.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(patchPatient.pending, (state) => {
        state.loading = true;
      })
      .addCase(patchPatient.fulfilled, (state, action) => {
        state.loading = false;
        state.patient = action.payload;
        state.success = true;
      })
      .addCase(patchPatient.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getPatientShippingAddresses.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPatientShippingAddresses.fulfilled, (state, action) => {
        state.loading = false;
        state.shipping_addresses = action.payload;
      })
      .addCase(getPatientShippingAddresses.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(postPatientShippingAddress.pending, (state) => {
        state.loading = true;
      })
      .addCase(postPatientShippingAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.shipping_addresses = {
          meta: state?.shipping_addresses?.meta,
          results: [action?.payload, ...state?.shipping_addresses?.results],
        };
      })
      .addCase(postPatientShippingAddress.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(patchPatientShippingAddress.pending, (state) => {
        state.loading = true;
      })
      .addCase(patchPatientShippingAddress.fulfilled, (state, action) => {
        const updatedAddresses = state?.shipping_addresses?.results?.map(
          (el) => {
            if (el?.id === action?.payload?.id) {
              return action.payload;
            } else {
              return el;
            }
          }
        );
        const newAddresses = {
          meta: state?.shipping_addresses?.meta,
          results: updatedAddresses,
        };
        state.shipping_addresses = newAddresses;
        state.loading = false;
      })
      .addCase(patchPatientShippingAddress.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(get_queued_patients.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_queued_patients.fulfilled, (state, action) => {
        state.loading = false;
        state.queued_patients = action.payload;
      })
      .addCase(get_queued_patients.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(postPatientQueue.pending, (state) => {
        state.loading = true;
      })
      .addCase(postPatientQueue.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(postPatientQueue.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(deleteQueuedPatient.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteQueuedPatient.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteQueuedPatient.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(deleteSelectedQueuedPatient.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteSelectedQueuedPatient.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteSelectedQueuedPatient.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })

  },
});
export const { addPatient, resetPatientError } = patientSlice.actions;
export default patientSlice.reducer;
