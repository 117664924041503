import React, { useEffect, useState } from "react";
import "./QueueDrawer.scss";
import { Drawer, Typography, Input, Button } from "antd";
import {
  CloseOutlined,
  SearchOutlined,
  UsergroupDeleteOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSelectedQueuedPatient,
  get_queued_patients,
} from "../../../../features/patient/patientActions";
import { PatientList } from "../../../../components";
import { ConfirmModal } from "../../../../modals";
import { resetPatientError } from "../../../../features/patient/patientSLice";
import LocalStorageService from "../../../../common/services/LocalStorageService";
import Utils from "../../../../utils";

export default function QueueDrawer({
  token,
  isOpened,
  close,
  handlePatient,
  openNotificationWithIcon,
}) {
  const dispatch = useDispatch();
  const {
    error,
    loading,
    queued_patients: patients,
  } = useSelector((state) => state?.patient);
  const [searchVal, setSearchVal] = useState("");
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const getPatientsByFilter = (params) => {
    dispatch(
      get_queued_patients({
        search: searchVal ? searchVal : undefined,
        ...params,
      })
    );
  };

  useEffect(() => {
    if (isOpened) {
      dispatch(get_queued_patients());
    }
  }, [dispatch, isOpened]);

  const removeFromQueue = (patient) => {
    if (LocalStorageService?.get("has_cashier_entered_desk_number")) {
      setSelectedPatient(patient);
      setIsConfirmModalOpened(true);
    } else {
      openNotificationWithIcon(
        "warning",
        "warning",
        "Please set cashier desk number."
      );
    }
  };

  const onSearchInpChange = Utils?.debounce((e) => {
    setSearchVal(e.target.value?.toLowerCase());
    getPatientsByFilter({
      search: e.target.value?.toLowerCase()
        ? e.target.value?.toLowerCase()
        : undefined,
    });
  }, 500);

  useEffect(() => {
    if (isOpened) {
      if (error && !loading) {
        for (let key in error) {
          openNotificationWithIcon("error", "Error", error[key]);
        }
        dispatch(resetPatientError());
      }
    }
  }, [error, loading, openNotificationWithIcon, dispatch, isOpened]);

  const closeDrawer = () => {
    if (typeof close === "function") {
      close();
      setSearchVal("");
      setIsConfirmModalOpened(false);
      setSelectedPatient(null);
      if (error) {
        dispatch(resetPatientError());
      }
    }
  };

  return (
    <>
      <Drawer
        className="queDrawer-cont"
        style={{
          backgroundColor: token?.Layout?.contentBg,
        }}
        destroyOnClose={true}
        placement="left"
        width={900}
        title={
          <Typography.Text
            className="queDrawer-cont__title"
            style={{
              color: token?.Typography?.mainColorText,
            }}>
            Manage Queue
          </Typography.Text>
        }
        open={isOpened}
        closeIcon={false}
        extra={
          <div className="queDrawer-cont__extraWrapper">
            <Button
              size="small"
              type="text"
              onClick={closeDrawer}
              icon={
                <CloseOutlined
                  style={{
                    color: token?.icons?.whiteIcon,
                    fontSize: "24px",
                  }}
                />
              }
            />
          </div>
        }
        onClose={closeDrawer}>
        <div className="queDrawer-cont__contentWrapper">
          <Input
            style={{
              width: "100%",
            }}
            size="large"
            placeholder="Search Queued Patients"
            prefix={<SearchOutlined />}
            onChange={onSearchInpChange}
            allowClear
          />
          <PatientList
            isCashier={true}
            loading={loading}
            patients={patients}
            handlePatient={(patient) => {
              if (LocalStorageService?.get("has_cashier_entered_desk_number")) {
                dispatch(deleteSelectedQueuedPatient(patient))?.then((res) => {
                  if (
                    res?.type === "delete_selected_queued_patient/fulfilled"
                  ) {
                    handlePatient(res?.payload, () => {
                      closeDrawer();
                    });
                  }
                });
              } else {
                openNotificationWithIcon(
                  "warning",
                  "warning",
                  "Please set cashier desk number."
                );
              }
            }}
            removeFromQueue={removeFromQueue}
          />
        </div>
      </Drawer>
      <ConfirmModal
        token={token}
        isOpened={isConfirmModalOpened}
        title={"Are you sure"}
        subTitle={`You want to remove  ${
          selectedPatient?.first_name ? selectedPatient?.first_name : ""
        } ${selectedPatient?.last_name ? selectedPatient?.last_name : ""}
        ${
          selectedPatient?.phone_number
            ? `+1 ${Utils?.format_phone_number(selectedPatient?.phone_number)}`
            : ""
        } from queue ?`}
        icon={<UsergroupDeleteOutlined style={{ fontSize: "24px" }} />}
        onCancel={() => {
          setIsConfirmModalOpened(false);
          setSelectedPatient(null);
        }}
        onOk={() => {
          setIsConfirmModalOpened(false);
          setSelectedPatient(null);
          dispatch(deleteSelectedQueuedPatient(selectedPatient))?.then(
            (res) => {
              if (res?.type === "delete_selected_queued_patient/fulfilled") {
                getPatientsByFilter();
              }
            }
          );
        }}
      />
    </>
  );
}
