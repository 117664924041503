import React, { useState } from "react";
import "./PatientCard.scss";
import { Typography, Button, Card, Image, Breadcrumb, Spin } from "antd";
import {
  CloseOutlined,
  ForwardOutlined,
  SelectOutlined,
} from "@ant-design/icons";
import QueueDrawer from "../QueueDrawer/QueueDrawer";
import { ConfirmModal } from "../../../../modals";
import { useSelector, useDispatch } from "react-redux";
import { deleteQueuedPatient } from "../../../../features/patient/patientActions";
import { resetPatientError } from "../../../../features/patient/patientSLice";
import not_found_image from "../../../../assets/not_fount_image.png";
import { CustomEmpty } from "../../../../components";
import LocalStorageService from "../../../../common/services/LocalStorageService";
import Utils from "../../../../utils";

export default function PatientCard({
  token,
  order_patient,
  set_order_patient,
  openNotificationWithIcon,
}) {
  const { error, loading } = useSelector((state) => state?.patient);
  const dispatch = useDispatch();
  const [is_queue_drawer_opened, set_is_queue_drawer_opened] = useState(false);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);

  const openQueueDrawer = () => {
    set_is_queue_drawer_opened(true);
    if (error) {
      dispatch(resetPatientError());
    }
  };

  const closeQueueDrawer = () => {
    set_is_queue_drawer_opened(false);
  };

  const handlePatient = (patient, cb) => {
    set_order_patient(patient);
    if (typeof cb === "function") {
      cb();
    }
  };

  const handleNextPatient = () => {
    if (LocalStorageService?.get("has_cashier_entered_desk_number")) {
      dispatch(deleteQueuedPatient())?.then((response) => {
        if (response?.type === "delete_queued_patient/fulfilled") {
          if (Array.isArray(response?.payload) && !response?.payload?.length) {
            openNotificationWithIcon(
              "warning",
              "Warning",
              "No patients in queue."
            );
          } else {
            set_order_patient(response?.payload);
          }
        } else if (response?.type === "delete_queued_patient/rejected") {
          const error = response?.payload;
          if (error?.Error) {
            openNotificationWithIcon("error", "Server Error", error?.Error);
          } else {
            for (let key in error) {
              openNotificationWithIcon("error", "Error", error[key]);
            }
          }
          dispatch(resetPatientError());
        }
      });
    } else {
      openNotificationWithIcon(
        "warning",
        "warning",
        "Please set cashier desk number."
      );
    }
  };

  return (
    <Spin spinning={loading && !is_queue_drawer_opened}>
      <div className="patientCardCont">
        <div className="patientCardCont__wrapper">
          <Typography.Text
            className="patientCardCont__text"
            style={{
              color: token?.Typography?.mainColorText,
            }}>
            Patient
          </Typography.Text>
          {!order_patient ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}>
              <Button
                size="small"
                title="View Patients Queue."
                icon={
                  <SelectOutlined
                    style={{
                      fontSize: "18px",
                    }}
                  />
                }
                onClick={openQueueDrawer}
              />
              <Button
                size="small"
                title="Handle Next Patient."
                icon={
                  <ForwardOutlined
                    style={{
                      fontSize: "18px",
                    }}
                  />
                }
                onClick={handleNextPatient}
              />
            </div>
          ) : null}
        </div>
        {order_patient ? (
          <Card
            key={order_patient?.id}
            className="patientCardCont__card"
            style={{
              border: "none",
              backgroundColor: token?.Card?.colorBgCard,
            }}
            styles={{
              body: {
                width: "100%",
                display: "flex",
                gap: "12px",
                padding: "8px",
              },
            }}>
            <Image
              width="46px"
              height="56px"
              src={
                order_patient?.id_image_url
                  ? order_patient?.id_image_url
                  : not_found_image
              }
            />
            <div className="patientCardCont__card__descriptionCont">
              <div className="patientCardCont__card__descriptionCont__deatilCont">
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    gap: "4px",
                  }}>
                  <Typography.Text
                    className="patientCardCont__card__descriptionCont__deatilCont__text"
                    style={{
                      color: token?.Typography?.mainColorText,
                    }}>
                    {`${order_patient?.first_name || ""} ${
                      order_patient?.last_name || ""
                    }`}
                  </Typography.Text>
                  <Button
                    size="small"
                    icon={
                      <CloseOutlined
                        style={{
                          fontSize: "18px",
                        }}
                      />
                    }
                    onClick={() => {
                      setIsConfirmModalOpened(true);
                    }}
                  />
                </div>
                <div className="patientCardCont__card__descriptionCont__deatilCont__textWrapper">
                  <Typography.Text
                    style={{
                      color: token?.Typography?.mainColorText,
                    }}
                    className="patientCardCont__card__descriptionCont__deatilCont__text"
                    ellipsis={{
                      tooltip: {
                        title: `${order_patient?.email || ""}`,
                      },
                    }}>
                    {order_patient?.email || ""}
                  </Typography.Text>
                  <Breadcrumb
                    separator="•"
                    items={[
                      {
                        title: order_patient?.phone_number ? (
                          <a href={`tel:${order_patient?.phone_number}`}>
                            <Typography.Text
                              style={{
                                color: token?.Typography?.mainColorText,
                              }}>
                              +1{" "}
                              {Utils?.format_phone_number(
                                order_patient?.phone_number
                              )}
                            </Typography.Text>{" "}
                          </a>
                        ) : (
                          <Typography.Text
                            style={{
                              color: token?.Typography?.mainColorText,
                            }}>
                            Phone
                          </Typography.Text>
                        ),
                      },
                      {
                        title: order_patient?.address ? (
                          <a
                            href={`${Utils?.getUrlInMap(
                              order_patient?.address
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer">
                            <Typography.Text
                              ellipsis={{
                                tooltip: {
                                  title: `${order_patient?.address}`,
                                },
                              }}
                              style={{
                                whiteSpace: "normal", // Allow line breaks between words
                                overflowWrap: "break-word", // Ensure long words break properly
                                display: "inline", // Ensure the ellipsis works with the text
                                color: token?.Typography?.mainColorText,
                              }}>
                              {order_patient?.address}
                            </Typography.Text>
                          </a>
                        ) : (
                          <Typography.Text
                            style={{
                              color: token?.Typography?.mainColorText,
                            }}>
                            Address
                          </Typography.Text>
                        ),
                      },
                    ]?.filter((item) => item !== null)}
                  />
                </div>
              </div>
            </div>
          </Card>
        ) : (
          <CustomEmpty description={"No patient"} width={"56"} height={"56"} />
        )}
        <QueueDrawer
          token={token}
          isOpened={is_queue_drawer_opened}
          close={closeQueueDrawer}
          handlePatient={handlePatient}
          openNotificationWithIcon={openNotificationWithIcon}
        />
        <ConfirmModal
          token={token}
          isOpened={isConfirmModalOpened}
          title={"Are you sure"}
          subTitle={`You want to unhandle the patient ?`}
          icon={<CloseOutlined style={{ fontSize: "24px" }} />}
          onCancel={() => {
            setIsConfirmModalOpened(false);
          }}
          onOk={() => {
            setIsConfirmModalOpened(false);
            set_order_patient(null);
          }}
        />
      </div>
    </Spin>
  );
}
