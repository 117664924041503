import React from "react";
import "./PatientList.scss";
import { theme, List, Typography, Image, Breadcrumb, Button } from "antd";
import useWindowWidth from "../../hooks/useWindowWidth";
import {
  UsergroupDeleteOutlined,
  // ArrowRightOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";
import NotFoundImage from "../../assets/not_fount_image.png";
import CustomEmpty from "../CustomEmpty/CustomEmpty";
import Utils from "../../utils";

export default function PatientList({
  isCashier,
  patients,
  loading,
  removeFromQueue,
  handlePatient,
}) {
  const { useToken } = theme;
  const { token } = useToken();
  const { width, isMobile } = useWindowWidth();

  return !loading && !patients?.length ? (
    <div className="patientList__wrapper">
      <CustomEmpty />
    </div>
  ) : (
    <List
      className="patientList"
      dataSource={
        Array.isArray(patients) ? Utils?.madeUnique(patients || []) : []
      }
      loading={loading}
      size="small"
      itemLayout="vertical"
      renderItem={(patient, index) => (
        <List.Item
          key={patient?.id}
          style={{
            backgroundColor: token?.List?.lisItemBg,
          }}
          className="patientList__item"
          styles={{
            extra: {
              marginTop: "0px",
              marginLeft: "6px",
            },
          }}
          extra={
            isCashier ? (
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}>
                <Button
                  size={"small"}
                  title="Handle Patient"
                  icon={
                    <ScheduleOutlined
                      style={{
                        fontSize: "18px",
                      }}
                    />
                  }
                  onClick={() => {
                    if (typeof handlePatient === "function") {
                      handlePatient(patient);
                    }
                  }}
                />
                <Button
                  size={"small"}
                  title="Remove From Queue"
                  icon={
                    <UsergroupDeleteOutlined
                      style={{
                        fontSize: "18px",
                      }}
                    />
                  }
                  onClick={() => {
                    if (typeof removeFromQueue === "function") {
                      removeFromQueue(patient);
                    }
                  }}
                />
              </div>
            ) : (
              <Button
                size={"middle"}
                title="Remove From Queue"
                icon={
                  <UsergroupDeleteOutlined
                    style={{
                      fontSize: "18px",
                    }}
                  />
                }
                onClick={() => {
                  if (typeof removeFromQueue === "function") {
                    removeFromQueue(patient);
                  }
                }}
              />
            )
          }>
          <List.Item.Meta
            className="patientList__item__meta"
            avatar={
              <Image
                style={{
                  width: "40px",
                  height: "50px",
                }}
                src={
                  patient?.id_image_url ? patient?.id_image_url : NotFoundImage
                }
                alt="p_img"
              />
            }
            title={
              <Typography.Text
                strong
                style={{
                  color: token?.Typography?.mainColorText,
                }}
                ellipsis={
                  isMobile && width <= 350
                    ? {
                        tooltip: {
                          title: `${
                            patient?.first_name ? patient?.first_name : ""
                          }
            ${patient?.last_name ? patient?.last_name : ""},
            ${patient?.email ? patient?.email : ""}`,
                        },
                      }
                    : null
                }>
                {`${patient?.first_name ? patient?.first_name : ""}
            ${patient?.last_name ? patient?.last_name : ""},
            ${patient?.email ? patient?.email : ""}`}
              </Typography.Text>
            }
            description={
              <Breadcrumb
                separator="•"
                items={[
                  {
                    title: patient?.phone_number ? (
                      <a href={`tel:${patient?.phone_number}`}>
                        <Typography.Text type="secondary">
                          +1 {Utils?.format_phone_number(patient?.phone_number)}
                        </Typography.Text>{" "}
                      </a>
                    ) : (
                      <Typography.Text type="secondary">Phone</Typography.Text>
                    ),
                  },
                  width > 767
                    ? {
                        title: patient?.dob ? (
                          <Typography.Text type="secondary">
                            {Utils?.format_date(patient?.dob)}
                          </Typography.Text>
                        ) : (
                          <Typography.Text type="secondary">
                            Date Of Birth
                          </Typography.Text>
                        ),
                      }
                    : null,
                  {
                    title: patient?.address ? (
                      <a
                        href={`${Utils?.getUrlInMap(patient?.address)}`}
                        target="_blank"
                        rel="noopener noreferrer">
                        <Typography.Text
                          className="patientList__item__meta__address"
                          ellipsis={{
                            tooltip: {
                              title: `${patient?.address}`,
                            },
                          }}
                          style={
                            isMobile && width > 350
                              ? {
                                  whiteSpace: "normal", // Allow line breaks between words
                                  overflowWrap: "break-word", // Ensure long words break properly
                                  display: "inline", // Ensure the ellipsis works with the text
                                }
                              : null
                          }>
                          {patient?.address}
                        </Typography.Text>
                      </a>
                    ) : (
                      <Typography.Text type="secondary">
                        Address
                      </Typography.Text>
                    ),
                  },
                ]?.filter((item) => item !== null)}
              />
            }
          />
          <div className="patientList__item__queWrapper">
            <Typography.Text
              style={{
                color: token?.Typography?.mainColorText,
              }}>{`${
              patient?.patient_code ? patient?.patient_code : ""
            }`}</Typography.Text>
            {/* <ArrowRightOutlined
              style={{
                color: token?.colorPrimary,
              }}
            />
            <Typography.Text
              style={{
                color: token?.Typography?.mainColorText,
              }}>
              {index}
            </Typography.Text> */}
          </div>
        </List.Item>
      )}
    />
  );
}
