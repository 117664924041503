import React, { useEffect, useCallback, useRef, useState } from "react";
import "./DeskNumber.scss";
import { Modal, Form, Typography, Input, notification } from "antd";
import { useDispatch } from "react-redux";
import LocalStorageService from "../../common/services/LocalStorageService";
import { postCahierDeskNumber } from "../../features/deskNumber/deskNumberActions";
import Utils from "../../utils";

export default function DeskNumber({ token, title, isOpened, onCancel }) {
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = useCallback(
    (type, title, text) => {
      api[type]({
        message: title,
        description: text,
        placement: "top",
      });
    },
    [api]
  );
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState([]);
  const is_modal_closable = LocalStorageService?.get(
    "has_cashier_entered_desk_number"
  )
    ? true
    : false;

  useEffect(() => {
    if (is_modal_closable && isOpened) {
      setFormData([
        {
          name: "desk_number",
          value: LocalStorageService?.get("has_cashier_entered_desk_number"),
        },
      ]);
    }
  }, [is_modal_closable, isOpened]);

  const postDeskNumber = (params) => {
    setLoading(true);
    dispatch(postCahierDeskNumber(params))?.then((res) => {
      if (res?.type === "post_cashier_desk_number/fulfilled") {
        onCancel();
        formRef?.current?.resetFields();
        setError(null);
        setFormData([]);
      } else if (res?.type === "post_cashier_desk_number/rejected") {
        if (res?.payload?.SERVER_ERROR) {
          openNotificationWithIcon(
            "error",
            "Server Error",
            res?.payload?.SERVER_ERROR
          );
        } else if (
          res?.payload?.detail &&
          typeof res?.payload?.detail === "string"
        ) {
          openNotificationWithIcon("error", "Error", res?.payload?.detail);
        } else if (res?.payload?.non_field_error) {
          if (
            Array.isArray(res?.payload?.non_field_error) &&
            res?.payload?.non_field_error
          ) {
            formRef?.current?.setFields([
              {
                name: "desk_number", // Field name you want to set the error for
                errors: [res?.payload["non_field_error"][0]], // Assuming your error has a 'message' field
              },
            ]);
          } else if (
            !Array.isArray(res?.payload?.non_field_error) &&
            typeof res?.payload?.non_field_error === "object" &&
            res?.payload?.non_field_error !== null
          ) {
            formRef?.current?.setFields([
              {
                name: "desk_number", // Field name you want to set the error for
                errors: [res?.payload["non_field_error"]], // Assuming your error has a 'message' field
              },
            ]);
          }
        }
        setError(res?.payload);
      }
      setLoading(false);
    });
  };
  
  return (
    <>
      {contextHolder}
      <Modal
        className="deskNumber-cont"
        styles={
          !is_modal_closable
            ? {
                mask: {
                  boxShadow: `inset 0 0 20px rgba(255, 255, 255, 0.6)`,
                  backdropFilter: "blur(20px)",
                },
                content: {
                  border: `1px solid ${token?.colorPrimary}`,
                },
              }
            : {}
        }
        open={isOpened}
        title={title}
        centered={true}
        closeIcon={is_modal_closable}
        destroyOnClose={true}
        maskClosable={is_modal_closable}
        okText={"Save"}
        cancelButtonProps={{
          className: "deskNumber-cont__displayNone",
        }}
        okButtonProps={{
          className: "deskNumber-cont__btn",
          htmlType: "submit",
          type: "primary",
          key: "submit",
          size: "large",
          loading: loading,
          disabled: error ? Object.keys(error)?.length !== 0 : false,
        }}
        onCancel={() => {
          if (is_modal_closable) {
            onCancel();
            formRef?.current?.resetFields();
            setFormData([]);
            if (error) {
              setError(null);
            }
          }
        }}
        onOk={() => {
          formRef?.current?.submit();
        }}>
        <Form
          className="deskNumber-cont__form"
          ref={formRef}
          layout="vertical"
          fields={formData}
          onFinish={(values) => {
            postDeskNumber({
              desk_number: Number(values?.desk_number),
            });
          }}
          onFinishFailed={() => {}}>
          <Form.Item
            className="deskNumber-cont__form__formItem"
            name="desk_number"
            rules={[
              {
                required: true,
                message: "Please enter your Desk Number!",
              },
              {
                validator: Utils?.positiveIntegerValidator,
              },
            ]}
            label={<Typography.Text>Desk Number</Typography.Text>}
            colon={false}>
            <Input
              placeholder="Desk Number"
              size="large"
              onChange={() => {
                if (error) {
                  setError(null);
                }
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
